
import { defineComponent, ref, onMounted, onUpdated, reactive } from "vue";

import ApiService from "@/core/services/ApiService";
import store from "@/store";

export default defineComponent({
  name: "newsPage",
  components: {
    //Datatable,
  },
  data() {
    return {
      newsItem: [],
      currentPage: Number(this.$route.params.page),
      currentMode: this.$route.params.mode,
      maxPage: 1,
    };
  },
  created: function () {
    this.getData(this.$route.params.page);
  },
  watch: {
    currentPage() {
      this.getData(this.currentPage);
      this.$router.push({
        name: "newsPage",
        params: { page: this.currentPage },
      });
      //this.currentPage = this.$route.params.page
    },
    $route(to, from) {
      if (to.name == "newsPage") {
        this.currentPage = Number(this.$route.params.page);
      }
    },
  },
  methods: {
    getData(page) {
      ApiService.post(
        process.env.VUE_APP_API_URL +
          "&mode=pub&tab=misc&type=getNews" + "&page=" + page,
        {}
      ).then((response) => {
        this.newsItem = response.data.result;
        this.maxPage = response.data.maxPage;
      });
    },
  },
});
